<template>
  <div class="container">
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Date</label>
      <div class="col-3">
        <datepicker v-model="form.date" typeable class="form-control" style="width: 280px"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Retailer</label>
      <div class="col-2">
        <select v-model="form.merchantType" class="form-select">
          <option v-for="(item, index) in retailerList" :key="index" :value="item">{{item}}</option>
        </select>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Product</label>
      <div class="col-6">
            <input type="text" v-model="form.product" class="form-control"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Quantity</label>
      <div class="col-2">
            <input type="text" v-model="form.quantity" class="form-control"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Price</label>
      <div class="col-2">
            <input type="text" v-model="form.price" class="form-control"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Fee</label>
      <div class="col-2">
            <input type="text" v-model="form.fee" class="form-control" placeholder="Optional"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Sold Price</label>
      <div class="col-2">
            <input type="text" v-model="form.soldPrice" class="form-control" placeholder="Optional"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Source</label>
      <div class="col-3">
        <input type="text" v-model="form.source" class="form-control" placeholder="Optional"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Status</label>
      <div class="col-2">
          <select v-model="form.status" class="form-select">
            <option v-for="(item, index) in statusList" :key="index" :value="item">{{item}}</option>
          </select>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-1 col-form-label">
        <button type="button" class="btn btn-primary" @click="formSubmit">Save Changes</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "DealEdit",
  data: function () {
    return {
      form: {
        id: this.$route.query.id || "",
        status: "Active",
        merchantType: "Best Buy"
      },
      action: this.$route.query.action || "",
      breadcrumbs: [{ name: "Deal", link: "/" }, { name: (this.$route.query.id ? "Update" : "New") + " Deal " + (this.$route.query.action || "") }],
      alertContent: "",
      alertClass: "alert-danger",
      retailerList: ["Best Buy", "Amazon", "Walmart", "Costco", "Dell", "Microsoft", "Google", "Sony", "Target", "Gamestop", "HP", "Sams Club", "HSN", "Sephora", "eBay", "Staples", "Newegg"],
      statusList: ["Active", "Inactive"],
      creditCardList: []
    };
  },
  mounted() {
      if (this.form.id) {
         this.getObject();
      }
  },
  methods: {
    formSubmit() {
      if (this.submitted) {
        return;
      }
      this.submitted = true;
      if (this.action && this.action === "COPY") {
        this.form.id = "";
      }
      axios.post("/deal", this.form)
        .then(() => {
          this.submitted = false;
          this.alertClass = "alert-success";
          this.alertContent = "Deal has been saved successfully!";
        })
        .catch((error) => {
          this.alertClass = "alert-danger";
          this.alertContent = error.response ? error.response.data : error;
          this.submitted = false;
        });
    },
    getObject() {
      axios
      .get("/deal/" + this.form.id)
      .then((response) => {
        this.form = response.data;
        this.form.date = new Date(this.form.date + "T00:00:00")
      })
      .catch((error) => {
        this.alertClass = "alert-danger";
        this.alertContent = error.response ? error.response.data : error;
      });
    }
  },
};
</script>
